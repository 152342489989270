/*================
###  Settings
=================*/
.section-setting {
  .panel-title {
    font-size: 18px;
  }

  .tab-wrap {
    margin-top: 5px;

    position: relative;
    border-bottom: 1px solid #e7e7e7;
    display: inline-block;

    .v-ripple__container {
      display: none !important;
    }

    .v-tab {
      background-color: transparent;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: none;

      &:not(:last-child) {
        margin-right: 15px;
      }

      &:hover,
      &:focus {
        &::before {
          opacity: 0;
        }
      }

      &:not(.v-tab--active) {
        color: #565656;
      }

      &.v-tab--active {
        color: var(--primary-color);
      }
    }

    .v-tabs-slider {
      border-radius: 4px 4px 0px 0px;
    }
  }

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border-color: var(--input-border);
  }

  .ql-toolbar.ql-snow {
    border-radius: 10px 10px 0px 0px;
  }

  .ql-container.ql-snow {
    border-radius: 0 0 10px 10px;
  }

  // .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
  // .ql-snow .ql-toolbar .ql-picker-item:hover,
  // .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  // .ql-snow .ql-toolbar .ql-picker-label:hover,
  // .ql-snow .ql-toolbar button.ql-active,
  // .ql-snow .ql-toolbar button:focus,
  // .ql-snow .ql-toolbar button:hover,
  // .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  // .ql-snow.ql-toolbar .ql-picker-item:hover,
  // .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  // .ql-snow.ql-toolbar .ql-picker-label:hover,
  // .ql-snow.ql-toolbar button.ql-active,
  // .ql-snow.ql-toolbar button:focus,
  // .ql-snow.ql-toolbar button:hover {
  //     color: var(--primary-color);
  // }

  .modal-body {
    .quillWrapper {
      .ql-container {
        .ql-tooltip {
          left: unset !important;
          right: unset !important;
          left: 0 !important;
        }
      }
    }
  }

  .drop {
    label.btn {
      margin-bottom: 0;
      width: 100%;
    }

    .image {
      min-height: 250px;

      .img-wrap {
        height: 100%;
        border-radius: 16px;
        overflow: hidden;

        img {
          // object-fit: none !important;
        }
      }

      .btn-remove {
        position: absolute;
        right: 10px;
        top: 10px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M7.64539 6.50286L12.7534 1.39425C13.5176 0.629994 12.3269 -0.4745 11.6059 0.246595L6.49786 5.35266L1.38728 0.246595C0.625639 -0.517664 -0.481272 0.673158 0.239744 1.39425L5.34779 6.50286L0.237205 11.6115C-0.496505 12.3453 0.638333 13.5056 1.38474 12.7591L6.49532 7.65052L11.6059 12.7591C12.3396 13.4929 13.4897 12.3453 12.7534 11.6115L7.64539 6.50286Z' fill='white'/%3E%3C/svg%3E");
        font-size: 0;
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #00000066;
        border-radius: 5px;
        background-repeat: no-repeat;
      }
    }
  }
}




/*================
### End Settings
=================*/
