/* Common scss */
body.RemoveOverflow {
  overflow-x: initial;
  overflow-y: initial;
}

div.container {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 100%;
}

.color-primary,
.primary-color {
  color: var(--primary-color);
}

.compensate-for-scrollbar {
  overflow: hidden;

  nav.main-navbar {
    z-index: 1;
  }
}

.checkbox-area {
  position: relative;
}

.req-field,
.required-field {
  color: var(--btn-primary);
}

main[role="main"] {
  padding: 40px;
  margin-top: 75px;
  margin-left: 300px;

  @include bp(sm) {
    margin-left: 260px;
    padding: 30px;
  }
}

div.v-card__title {
  padding: 0;
}

.status {
  font-weight: normal;
  font-size: 12px;
  padding: 2px 8px;
  text-transform: capitalize;
  border-radius: 8px;

  &.v-chip {
    border-radius: 8px;
  }

  &.v-size--default {
    height: auto;
    padding: 2px 8px;
  }

  &.v-chip--active,
  &.success {
    color: #57daa1;
    background: #ddf8ec;
    border-radius: 8px;
  }

  &.v-chip--inactive,
  &.fail {
    background: #e7e7e7 !important;
    color: var(--text-color);
  }

  &.hidden {
    display: none;
  }
}

.link {
  color: var(--link-color) !important;
}

.link-email {
  text-decoration: underline;
  color: var(--text-color) !important;
}

/* common section */
.section {
  .v-data-table {
    table {
      tbody {
        .thumb-wrap {
          .border-img {
            height: 50px;
            width: 50px;
            border-radius: 14px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

/*End common section */



/* color picker */
.color_picker {
  position: relative;

  .icp__input {
    position: absolute;
    right: 10px;
    top: 38px;
  }
}

/* End color picker */
/* End Common scss */
